/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable prettier/prettier */
import Container from '@kitconcept/volto-light-theme/components/Atoms/Container/Container';
import LOGO from '../../assets/logo_flanders.svg';
import LOGO_DARK from '../../assets/logo_flanders_dark.svg';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  OfficialWebsite: {
    id: 'OfficialWebsite',
    defaultMessage:
      'Vlaamse Milieumaatschappij is een officiële website van de Vlaamse overheid',
    // Vlaamse Milieumaatschappij is een officiële website van de Vlaamse overheid
  },
  PublishedBy: {
    id: 'PublishedBy',
    defaultMessage: 'Published by', // uitgegeven door
  },
  Privacy: {
    id: 'Privacy',
    defaultMessage: 'Privacy', // Pivacy
  },
  CookiePolicy: {
    id: 'CookiePolicy',
    defaultMessage: 'Cookie policy', // Cookieverklaring
  },
  Accessibility: {
    id: 'Accessibility',
    defaultMessage: 'Accessibility', // Toegankelijkheid
  },
  Dutch: {
    id: 'Dutch',
    defaultMessage: 'NL', // NL
  },
  English: {
    id: 'English',
    defaultMessage: 'EN', // EN
  },
});

export const FlandersFooter = () => {
  const intl = useIntl();

  return (
    <div className="flanders-footer footer">
      <Container layout className="flanders-footer__container">
        <img
          src={LOGO}
          alt="Vlaanderen logo"
          className="flanders-footer__logo desktop"
        />
        <img
          src={LOGO_DARK}
          alt="Vlaanderen logo"
          className="flanders-footer__logo mobile"
        />
        <div className="flanders-footer__content">
          <h2>{intl.formatMessage(messages.OfficialWebsite)}</h2>
          <p>
            {intl.formatMessage(messages.PublishedBy)} <a href="#">VMM</a>
          </p>
          <div className="flanders-footer__links">
            <a href="/privacy">{intl.formatMessage(messages.Privacy)}</a>
            <a href="/cookie-policy">
              {intl.formatMessage(messages.CookiePolicy)}
            </a>
            <a href="/toegankelijkheid">
              {intl.formatMessage(messages.Accessibility)}
            </a>
          </div>
        </div>
        <div className="flanders-footer__languages">
          <a href="#">{intl.formatMessage(messages.Dutch)}</a>
          <a href="#">{intl.formatMessage(messages.English)}</a>
        </div>
      </Container>
    </div>
  );
};
