import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCmore from 'highcharts/highcharts-more';
import { mergeDeepRight } from 'ramda';

if (typeof Highcharts === 'object') {
  HCmore(Highcharts);
}

const color = '#0c3543';
const rgba = 'rgba(12, 53, 67, .1)';
const fontFamily = 'Flanders-art-sans, sans-serif';
const typoStyle = {
  color,
  fontFamily,
  fontSize: '12px',
};
const theme = {
  colors: [
    '#a461a8',
    '#27b7eb',
    '#186e8b',
    '#c7b751',
    '#857b3d',
    '#a1ec3b',
    '#939698',
    '#2b908f',
    '#f45b5b',
    '#91e8e1',
    '#00E789',
    '#1D9BF0',
    '#1A6F8C',
  ],
  chart: {
    style: typoStyle,
    backgroundColor: 'transparent',
  },
  tooltip: {
    style: { ...typoStyle, lineHeight: '18px', fontSize: '18px' },
    shadow: false,
    backgroundColor: '#ffffff',
    borderWidth: 1,
    borderColor: 'rgba(216, 227, 230, 0.70)',
  },
  title: {
    style: {
      ...typoStyle,
      fontSize: '18px',
      fontWeight: '500',
    },
  },
  subtitle: {
    style: {
      ...typoStyle,
      fontSize: '18px',
      fontWeight: '500',
    },
  },
  legend: {
    itemStyle: {
      ...typoStyle,
      opacity: 1,
    },
    itemHoverStyle: {
      color,
      opacity: 0.7,
    },
  },
  xAxis: {
    gridLineColor: 'none',
    lineColor: rgba,
    tickColor: rgba,
    lineWidth: 1,
    labels: {
      style: {
        ...typoStyle,
        opacity: 0.6,
      },
    },
    title: {
      style: typoStyle,
    },
  },
  yAxis: {
    labels: {
      style: {
        ...typoStyle,
        opacity: 0.6,
      },
    },
    lineWidth: 1,
    gridLineColor: rgba,
    lineColor: 'none',
    tickColor: rgba,
    title: {
      style: typoStyle,
    },
  },
  credits: {
    style: {
      fontSize: '1rem',
    },
  },
};

const mergeWithTheme = (
  options: Omit<typeof theme, 'xAxis' | 'yAxis'> & { xAxis: {}[]; yAxis: {}[] },
) => {
  const { xAxis, yAxis, ...rest } = theme;
  const newOptions = mergeDeepRight(options, rest);
  newOptions.xAxis = newOptions.xAxis.map((a) => mergeDeepRight(a, xAxis));
  newOptions.yAxis = newOptions.yAxis.map((a) => mergeDeepRight(a, yAxis));
  return newOptions;
};
type Props = {
  options: any;
};
export const Graph = (props: Props) => {
  const options = props.options;
  if (!options) {
    return '';
  }
  if (options?.type === 'Unauthorized') return;
  const mergedOptions = mergeWithTheme(options);
  return <HighchartsReact highcharts={Highcharts} options={mergedOptions} />;
};
