import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { H5 } from '../../Typography';
import { defineMessages, useIntl } from 'react-intl';

type AgendaProps = {
  content: {
    title: string;
    description: string;
    preview_image: {
      download: string;
    };
    preview_caption: string;
    location: string;
    start: string;
  };
};

// Define translatable messages
const messages = defineMessages({
  PracticalInfo: {
    id: 'PracticalInfo',
    defaultMessage: 'Practical information', // Praktische informatie
  },
  Date: {
    id: 'Date',
    defaultMessage: 'Date', // Datum
  },
  Location: {
    id: 'Location',
    defaultMessage: 'Location', // Locatie
  },
});

const Agenda = ({ content }: AgendaProps) => {
  const intl = useIntl();

  return (
    <div id="page-document" className="agenda">
      <div className="agenda-flex">
        <div className="agenda-left">
          <RenderBlocks content={content} />
        </div>
        <div className="agenda-right">
          <div className="right-side-section">
            <H5>{intl.formatMessage(messages.PracticalInfo)}</H5>
            <div className="practical-info">
              <p>{intl.formatMessage(messages.Date)}</p>
              <p>
                {new Intl.DateTimeFormat('nl-NL', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                }).format(new Date(content?.start))}
              </p>
            </div>
            <div className="practical-info">
              <p>{intl.formatMessage(messages.Location)}</p>
              <p>{content?.location}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agenda;
