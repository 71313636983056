import type { BlockViewProps, RelatedItem } from '@plone/types';
import cx from 'classnames';
import { FormattedDate, Image } from '@plone/volto/components';
import { H3, Link, Text } from '../../Typography';
import { Box } from '../../Box';
import { ReadMoreLink } from '../../ReadMoreLink';
import { Button } from '../../Button';
import downloadSvg from '../../../assets/download.svg';

type PublicationTeaserData = {
  title: string;
  head_title?: string;
  hide_description: boolean;
  description: string;
  preview_image: undefined | { image_field: string }[];
  createdAt: string;
  href:
    | undefined
    | (RelatedItem & { hasPreviewImage: boolean; created: string })[];
};
export const Publication = ({
  data,
  content,
  isEditMode,
}: BlockViewProps & {
  data: PublicationTeaserData;
  isEditMode: boolean;
  content?: {
    styles: { gridCutoff: number };
  };
}) => {
  const href = data.href?.[0];
  const image = data.preview_image?.[0];
  const horizontal = (content?.styles?.gridCutoff || 0) <= 2;

  const Wrapper = isEditMode ? Box : Link;

  const date = href && href.created;

  return (
    <div
      className={cx(
        'teaser',
        'publication-teaser',
        horizontal && 'publication-teaser--horizontal',
      )}
    >
      {href && (
        <Wrapper
          openLinkInNewTab={false}
          href={href['@id']}
          className="teaser-item default"
        >
          {(href.hasPreviewImage || href.image_field || image) && (
            <div className="image-wrapper">
              <Image
                item={image || href}
                imageField={image ? image.image_field : href.image_field}
                alt=""
                loading="lazy"
                responsive={true}
              />
            </div>
          )}
          <div className="teaser__content">
            {data?.head_title && <Text size="h2">{data.head_title}</Text>}

            <H3 size="h3" className="headline">
              {data?.title}
            </H3>
            {!data.hide_description && (
              <Text size="sm" weight="400">
                {data?.description}
              </Text>
            )}
            {date && (
              <Text size="xs" className="teaser__created-at">
                <FormattedDate
                  includeTime={false}
                  date={date}
                  format={{
                    dateStyle: 'long',
                  }}
                />
              </Text>
            )}
            <div className="teaser__outline-spacer" />
            <div className="teaser__links">
              <ReadMoreLink item={href} />
              <Button color="tertiary" size="medium">
                <img alt="Download" src={downloadSvg} />
              </Button>
            </div>
          </div>
        </Wrapper>
      )}
    </div>
  );
};
