import type { BlockViewProps } from '@plone/types';
import { Image } from '@plone/volto/components';
import { H2, Link, Text } from '../../Typography';
import { Box } from '../../Box';
import { useBreakpoint } from '../../../helpers/useMedia';
import { Button, ButtonLink } from '../../Button';
type defaultTeaserData = {
  title: string;
  head_title?: string;
  hide_description: boolean;
  description: string;
  preview_image: undefined | { image_field: string }[];
  createdAt: string;
  href:
    | undefined
    | {
        '@id': string;
        hasPreviewImage: boolean;
        image_field: string;
        created: string;
      }[];
};
export const StandaloneTeaser = ({
  data,
  isEditMode,
  className,
}: BlockViewProps & { data: defaultTeaserData; isEditMode: boolean }) => {
  const href = data.href && data.href[0];
  const image = data.preview_image?.[0];

  const Wrapper = isEditMode ? Box : Link;

  const isBigScreen = useBreakpoint('md');

  const hasLink = href && !!href;
  let link =
    hasLink && isEditMode ? (
      <ButtonLink
        openLinkInNewTab={false}
        item={href}
        href={null}
        size="large"
        textColor="white"
        color="tertiary"
        icon
      >
        Lees meer
      </ButtonLink>
    ) : (
      <Button size="large" textColor="white" color="tertiary" icon>
        Lees meer
      </Button>
    );

  return (
    <div
      className={`teaser standalone-teaser default-teaser ${!isBigScreen && 'mobile'} ${className}`}
    >
      {href && (
        <div className="teaser-item default ui twelve column grid">
          {(href.hasPreviewImage || href.image_field || image) && (
            <Wrapper
              openLinkInNewTab={false}
              href={href['@id']}
              className="image-wrapper twelve wide mobile five wide tablet five wide computer column"
            >
              <Image
                item={image || href}
                imageField={image ? image.image_field : href.image_field}
                alt=""
                loading="lazy"
                responsive={true}
              />
            </Wrapper>
          )}
          <div className="one wide column" />
          <div className="teaser__content twelve wide mobile five wide tablet five wide computer column ">
            {data?.title && <H2 size="h2">{data.title}</H2>}

            <div className="teaser__title-underline" />

            {!data.hide_description && (
              <Text size="md" weight="400">
                {data?.description}
              </Text>
            )}
            {link}
          </div>
          <div className="one wide column" />
        </div>
      )}
    </div>
  );
};
