import { Text } from '../../Typography';
import type { BlockViewProps, RelatedItem } from '@plone/types';
import { useBreakpoint } from '../../../helpers/useMedia';
import { Image } from '@plone/volto/components';
import { format } from 'date-fns';
import SummaryTemplate from '../../../customizations/@kitconcept/volto-light-theme/components/Listing/SummaryTemplate';
import { TagSocial } from '../../TagSocial';
import { ConditionalLink } from '@plone/volto/components';

export const WhatsNewTemplate = ({
  items,
  spotlightItem,
  buttonLink,
  isEditMode,
}: BlockViewProps & {
  items: RelatedItem[];
  blocks_layout: { items: any[] };
  spotlightItem: [RelatedItem];
  isEditMode: boolean;
}) => {
  let data = null;
  let buttonLinkData = null;
  if (spotlightItem && spotlightItem.length >= 1) {
    data = spotlightItem[0];
  }
  if (buttonLink && buttonLink.length >= 1) {
    buttonLinkData = spotlightItem[0];
  }
  const isBigScreen = useBreakpoint('lg');

  return (
    <>
      <div
        className={`wat-is-er-nieuw-block ${isBigScreen ? 'big-screen' : ''}`}
      >
        <div className="summary-spotlight">
          <ConditionalLink
            condition={!isEditMode}
            item={data}
            to={undefined}
            openLinkInNewTab={false}
          >
            <Image item={data} className="mainArticleImg" />
            <div className="summary-spotlight-body">
              <p>{data?.Date && format(data?.Date, 'dd MMMM yyyy')}</p>
              <Text size="h2" className="headline">
                {data?.title}
              </Text>
              <Text size="md" weight="400">
                {data?.description}
              </Text>
              <TagSocial
                showSocials={false}
                isEditMode={isEditMode}
                tags={data?.tags}
              />
            </div>
          </ConditionalLink>
        </div>
        <div className={`summary-container ${isBigScreen ? 'big-screen' : ''}`}>
          <SummaryTemplate items={items} buttonLink={buttonLinkData} />
        </div>
      </div>
    </>
  );
};
