import { ComponentProps, HTMLAttributes } from 'react';
import { UniversalLink } from '@plone/volto/components';
import cx from 'classnames';
import ICON from '../../assets/arrow-transparent.svg';
import ICONPRIMARY from '../../assets/arrow-primary.svg';

export type ButtonStyleProps = {
  glow?: boolean;
  size: 'large' | 'medium' | 'small' | 'input';
  color: 'primary' | 'secondary' | 'tertiary' | 'senary' | 'quinary' | 'border';
  border?: 'primary';
  halfRounded?: boolean;
  className?: string;
  icon?: string | boolean;
  square?: boolean;
};

const buttonClassnames = ({
  glow,
  className,
  size,
  halfRounded,
  color,
  border,
  square,
}: ButtonStyleProps) =>
  cx(
    [
      'button',
      `button--${size}`,
      `button--${color}`,
      halfRounded && 'button--half-rounded',
      square && 'button--square',
      className,
      `button--border--${border || color}`,
    ],
    {
      'button--glow': glow,
    },
  );

export const Button = (
  props: ButtonStyleProps & HTMLAttributes<HTMLButtonElement>,
) => {
  const className = buttonClassnames(props);
  const icon =
    typeof props.icon === 'string'
      ? props.icon
      : props.color !== 'border'
        ? ICON
        : ICONPRIMARY;
  return (
    <button {...props} className={className}>
      <span>{props?.children}</span>
      {props?.icon && <img src={icon} alt="Knop icoon" />}
    </button>
  );
};
Button.defaultProps = {
  color: 'primary',
  size: 'medium',
  textColor: 'white',
  icon: false,
};

export const ButtonLink = (
  props: ComponentProps<typeof UniversalLink> & ButtonStyleProps,
) => {
  const className = buttonClassnames(props);
  const icon =
    typeof props.icon === 'string'
      ? props.icon
      : props.color !== 'border'
        ? ICON
        : ICONPRIMARY;

  return (
    <UniversalLink {...props} className={className}>
      <span>{props.children}</span>
      {props?.icon && <img src={icon} alt="Knop icoon" />}
    </UniversalLink>
  );
};
ButtonLink.defaultProps = {
  color: 'primary',
  size: 'medium',
  textColor: 'white',
};
