import { Image } from '@plone/volto/components';
import { BlockContainer } from '../BlockContainer';
import { Graph } from '../Graph';

type Props = {
  content: {
    image: any;
    json_config: any;
  };
};

export const GraphTemplate = (props: Props) => {
  // If there is a static image, we show it, otherwise we show a dynamic graph.
  const options = props.content.json_config;
  if (props.content.image) {
    return (
      <BlockContainer className="graph">
        <div className="image-wrapper">
          <Image
            item={props.content}
            imageField={'image'}
            alt=""
            loading="lazy"
            responsive={true}
          />
        </div>
      </BlockContainer>
    );
  } else if (options) {
    return (
      <BlockContainer className="graph">
        <Graph options={options} />
      </BlockContainer>
    );
  }
};
