// SemanticUI-free pre-@plone/components

import { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { doesNodeContainClick } from 'semantic-ui-react/dist/commonjs/lib';
import { useIntl, defineMessages, injectIntl } from 'react-intl';
import cx from 'classnames';
import { UniversalLink } from '@plone/volto/components';
import { getBaseUrl, hasApiExpander } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import IntranetSearchWidget from '../SearchWidget/IntranetSearchWidget';
import { useBreakpoint } from '../../../../helpers/useMedia';
import { getNavigation } from '@plone/volto/actions';
import NavItem from '@plone/volto/components/theme/Navigation/NavItem';
import { ButtonLink as VMMButton } from '../../../../components/Button';

const messages = defineMessages({
  closeMenu: {
    id: 'Close menu',
    defaultMessage: 'Close menu',
  },
  openFatMenu: {
    id: 'Open menu',
    defaultMessage: 'Open menu',
  },
});

const Navigation = ({ pathname }) => {
  const [currentOpenIndex, setCurrentOpenIndex] = useState(null);
  const navigation = useRef(null);
  const dispatch = useDispatch();
  const intl = useIntl();
  const enableFatMenu = config.settings.enableFatMenu;
  const lang = useSelector((state) => state.intl.locale);
  const token = useSelector((state) => state.userSession.token, shallowEqual);
  // Restrict the main menu items to the length of menuBodies: 5 (temporary 6).
  const items = useSelector(
    (state) => state.navigation.items.slice(0, 6),
    shallowEqual,
  );

  const openMenu = useCallback((index) => {
    setCurrentOpenIndex((s) => (s === index ? null : index));
  }, []);

  const closeMenu = useCallback(() => {
    setCurrentOpenIndex(null);
  }, []);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (navigation.current && doesNodeContainClick(navigation.current, e))
        return;
      closeMenu();
    };

    document.addEventListener('mousedown', handleClickOutside, false);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  }, [closeMenu]);

  useEffect(() => {
    if (!hasApiExpander('navigation', getBaseUrl(pathname))) {
      dispatch(getNavigation(getBaseUrl(pathname), config.settings.navDepth));
    }
  }, [pathname, token, dispatch]);

  const isActive = (url) => {
    return (url === '' && pathname === '/') || (url !== '' && pathname === url);
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        closeMenu();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [closeMenu]);

  const isBigScreen = useBreakpoint('xl');
  const collapseSearch = useBreakpoint(1300);

  const menuBodies = [
    () => (
      <div className="headerTitleBlock">
        <h2>Ons milieu: de cijfers en feiten</h2>
        <p>
          Hoe zit het met de luchtkwaliteit in Vlaanderen? Wat is de actuele
          kans op een overstroming? Deze en veel meer vragen worden beantwoord
          met heldere cijfers en kaarten uit jouw omgeving.
        </p>
        <VMMButton
          color="secondary"
          size="large"
          halfrounded="true"
          icon={true}
          href="/feiten-cijfers/catalogus-feiten-cijfers"
          onClick={() => closeMenu()}
        >
          Bekijk alle cijfers en kaarten
        </VMMButton>
      </div>
    ),
    () => (
      <div className="headerTitleBlock">
        <h2>Hoe werken we aan een beter milieu?</h2>
        <p>
          Welke wetgeving en normen hanteert de overheid voor water, lucht en
          klimaatadaptatie in Vlaanderen?
          <br />
          Ontdek de regels en het beleid waarmee we werken aan een beter
          leefmilieu.
        </p>
      </div>
    ),
    () => (
      <div className="headerTitleBlock">
        <h2>Hoe kunnen we jou helpen?</h2>
        <p>
          Advies nodig over je vergunning of subsidieaanvraag?
          <br />
          Je heffingsaangifte of een aanvraag bij VMM indienen?
        </p>
        <VMMButton
          color="secondary"
          size="large"
          halfrounded="true"
          icon={true}
          href="/diensten-producten"
          onClick={() => closeMenu()}
        >
          Bekijk alle diensten & producten
        </VMMButton>
      </div>
    ),
    () => (
      <div className="headerTitleBlock">
        <h2>Waar zijn we aan de slag?</h2>
        <p>
          Met tal van lokale en internationale partners werkt de VMM aan heel
          wat milieuprojecten.
        </p>
        <VMMButton
          color="secondary"
          size="large"
          halfrounded="true"
          icon={true}
          href="/projecten"
          onClick={() => closeMenu()}
        >
          Bekijk alle projecten
        </VMMButton>
      </div>
    ),
    () => (
      <div className="headerTitleBlock">
        <h2>Wat kan jij doen?</h2>
        <p>
          Een beter milieu begint bij jezelf. Ontdek onze tips waarmee je in
          jouw buurt aan de slag kan.
        </p>
      </div>
    ),
    () => <div className="headerTitleBlock"></div>,
    () => <div className="headerTitleBlock"></div>,
    () => <div className="headerTitleBlock"></div>,
  ];

  return (
    <nav
      id="navigation"
      aria-label="navigation"
      className="navigation"
      style={{ flexDirection: 'row', justifyContent: 'space-between' }}
      ref={navigation}
    >
      <ul className="desktop-menu">
        {items.map((item, index) => (
          <li key={item.url}>
            {enableFatMenu ? (
              <>
                <button
                  onClick={() => openMenu(index)}
                  className={cx('item', {
                    active:
                      currentOpenIndex === index ||
                      (currentOpenIndex !== null && pathname === item.url),
                  })}
                  aria-label={intl.formatMessage(messages.openFatMenu)}
                  aria-expanded={currentOpenIndex === index ? true : false}
                >
                  {item.title}
                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1.34961L5.15 5.49961C5.19489 5.54744 5.24911 5.58556 5.30931 5.61162C5.3695 5.63768 5.4344 5.65113 5.5 5.65113C5.5656 5.65113 5.6305 5.63768 5.69069 5.61162C5.75089 5.58556 5.80511 5.54744 5.85 5.49961L10 1.34961"
                      stroke="#0C3543"
                      strokeWidth="1.7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>

                <div className="submenu-wrapper">
                  <div
                    className={cx('submenu', {
                      active: currentOpenIndex === index,
                    })}
                  >
                    <div className="submenu-inner">
                      {menuBodies[index]()}
                      <Grid className="navItemsBlock" columns={2}>
                        {item.items &&
                          item.items.length > 0 &&
                          item.items.slice(0, 4).map((subitem) => (
                            <Grid.Column
                              computer={12}
                              largeScreen={6}
                              widescreen={6}
                              key={subitem.url}
                              className="subitem-wrapper"
                            >
                              <UniversalLink
                                className="subitem-header"
                                onClick={() => closeMenu()}
                                item={subitem}
                              >
                                <p
                                  className={cx({
                                    current: isActive(subitem.url),
                                  })}
                                >
                                  {subitem.nav_title || subitem.title}
                                </p>
                                <svg
                                  width="20"
                                  height="25"
                                  viewBox="0 0 20 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    opacity="0.1"
                                    cx="10"
                                    cy="15"
                                    r="10"
                                    fill="#1D9BF0"
                                  />
                                  <path
                                    d="M8.52734 11L12.1273 14.6C12.182 14.6513 12.2256 14.7133 12.2554 14.7821C12.2851 14.8509 12.3005 14.925 12.3005 15C12.3005 15.075 12.2851 15.1491 12.2554 15.2179C12.2256 15.2867 12.182 15.3487 12.1273 15.4L8.52734 19"
                                    stroke="#1D9BF0"
                                    strokeWidth="1.71429"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </UniversalLink>
                              <span className="pageDescription">
                                {subitem.description}
                              </span>
                            </Grid.Column>
                          ))}
                      </Grid>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <NavItem
                collapsable={!isBigScreen}
                item={item}
                lang={lang}
                key={item.url}
              />
            )}
          </li>
        ))}
      </ul>
      <IntranetSearchWidget collapsable={!collapseSearch} />
    </nav>
  );
};

Navigation.propTypes = {
  pathname: PropTypes.string.isRequired,
};

Navigation.defaultProps = {
  token: null,
};

export default injectIntl(Navigation);
