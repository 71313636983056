import cx from 'classnames';
import { useEffect, useState } from 'react';
import type { BlockViewProps } from '@plone/types';
import {
  getIndicators,
  warnings,
  Indicator as IndicatorType,
} from './indicators';
import { IndicatorHeaderBlockData } from './Edit';
import { Card } from '../../Card';
import { Indicator } from '../../Indicator';
import { Warnings } from '../../Warnings';
import { useBreakpoint } from '../../../helpers/useMedia';

export const BlankIndicatorHeader = ({
  data,
}: BlockViewProps & { data: IndicatorHeaderBlockData; small?: boolean }) => {
  const [mappedIndicators, setIndicators] = useState<IndicatorType[]>([]);

  useEffect(() => {
    getIndicators()
      .then(
        (arr) =>
          arr.filter((indicator) => {
            return data.indicators?.includes(indicator.id);
          }) || [],
      )
      .then(setIndicators);
  }, [data.indicators, setIndicators]);

  const isBigScreen = useBreakpoint('lg');

  return (
    <div
      className={cx('indicator-header', 'indicator-header--blank', {
        'indicator-header--mobile': !isBigScreen,
        'indicator-header--small': true,
      })}
    >
      <div className="indicator-header__container">
        <div className="indicator-header__content">
          <div className="indicator-header__text">
            <h1>{data.title}</h1>
            <p>{data.subTitle}</p>
          </div>
          {isBigScreen && (
            <div className="indicator-header__indicators">
              <Card border className="indicator-header__indicator-card">
                {mappedIndicators.map(
                  (indicator, index) =>
                    indicator && (
                      <Indicator dark indicator={indicator} key={index} />
                    ),
                )}
              </Card>
              {data.showWarnings && (
                <Card dark className="warnings">
                  <Warnings warnings={warnings} />
                </Card>
              )}
            </div>
          )}
        </div>
      </div>
      {!isBigScreen && (
        <div className="indicator-header__indicators">
          <div className="indicator-header__indicators-list">
            {mappedIndicators.map(
              (indicator, index, arr) =>
                indicator && (
                  <Indicator
                    key={index}
                    indicator={indicator}
                    vertical={arr.length !== 1}
                  />
                ),
            )}
          </div>
          {data.showWarnings && <Warnings warnings={warnings} />}
        </div>
      )}
    </div>
  );
};
