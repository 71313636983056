import type { RelatedItem } from '@plone/types';
import { Text, H3 } from '../../Typography';
import cx from 'classnames';
import { ButtonLink } from '../../Button';

type CTATeaserData = {
  title: string;
  description: string;
  href: undefined | RelatedItem[];
  styles: Partial<{ gridCutoff: 2 | 3 | 4; solid: boolean }>;
};

export const CTATeaser = ({
  data,
  isEditMode,
}: {
  isEditMode: boolean;
  data: CTATeaserData;
}) => {
  const item = data.href?.[0];
  return (
    <div className={cx('teaser', 'cta-teaser')}>
      <div className="teaser-item">
        <div className="teaser__content">
          <H3 className="headline" size="h3" weight="500">
            {item?.title}
          </H3>
          <div className="teaser__title-underline" />
          <Text size="md">{data.description}</Text>
          <ButtonLink
            openLinkInNewTab={false}
            href={null}
            item={item}
            color="tertiary"
            size="large"
            icon
          >
            Lees meer
          </ButtonLink>
        </div>
      </div>
    </div>
  );
};
