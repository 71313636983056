import { Warning } from '../Blocks/IndicatorHeader/indicators';
import { defineMessages, useIntl } from 'react-intl';

type Props = {
  warnings: Warning[];
};

// Define translatable messages
const messages = defineMessages({
  warnings: {
    id: 'Warnings',
    defaultMessage: 'Warnings:', // Waarschuwingen:
  },
  warningIconAlt: {
    id: 'WarningIconAlt',
    defaultMessage: 'Warning icon', // Waarschuwingen icoon
  },
});

export const Warnings = ({ warnings }: Props) => {
  const intl = useIntl();

  return (
    <div className="warnings">
      <h5>{intl.formatMessage(messages.warnings)}</h5>
      {warnings.map((warning, index) => (
        <a href={warning.href} key={index} className="warning-item">
          <img
            src={warning.icon}
            alt={intl.formatMessage(messages.warningIconAlt)}
            className="warning-icon"
          />
          <span className="warning-label">{warning.label}</span>
        </a>
      ))}
    </div>
  );
};
